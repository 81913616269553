import KukanManagedClass from "../components/KukanManagedClass";
import React, { useEffect, useState } from "react";
import MenuCreateEditKukan from "../components/MenuCreateEditKukan";
import { initItemsKukan, ITEMS_KUKAN } from "../components/InitItemsKukan";
import { getAddressMP } from "../components/Mpurse";
import { checkServerResponse } from "../components/MonapartyAPI";
import { manager } from "../components/ObjectLoaderKukan";
import { Emoji } from "../components/Utils";

let kouzouData: File = null;
const Edit = () => {

  const [ menuState, setMenuState ] = useState(0);
  const [ userAddr, setUserAddr ] = useState("");
  const [ importData, setImportData ] = useState("");
  
  useEffect(() => {
    const element = document.getElementById("canvas");
    if(element != null) { 
      element.remove() }
  }, []);

  manager.onLoad = () => {
    // bootReq();
  }

  function checkImportData(event) {
    try {
      setImportData(JSON.parse(event.target.value));
    }
    catch(e) { console.error(e) }

  }

  async function init() {
    setMenuState(1);
    // setLoginState();
    if(ITEMS_KUKAN.env === "t" || ITEMS_KUKAN.env === "p") {
      const addressMP = await getAddressMP();
      if(addressMP === "") {
        if(typeof window !== `undefined`) { window.location.href = location.href.slice(0, -5) }
      }
      setUserAddr(addressMP);
      // await reqKukanData(addressMP);
    } else {
      setUserAddr(ITEMS_KUKAN.envAddress);
      
      // await reqKukanData(ITEMS_KUKAN.envAddress);
    }

    if(importData === "") {
      ITEMS_KUKAN.monaparty_server_url = await checkServerResponse();
      initItemsKukan("create");
      setMenuState(2);
    } else {
      ITEMS_KUKAN.monaparty_server_url = await checkServerResponse();
      initItemsKukan("edit");
      setMenuState(2);
    }
  }
 
  if(menuState === 1) {
    return (
      <>
        <LoadingWindow />
      </>
    );
  } else if(menuState === 2) {
    return (
      <>
        <LoadingWindow />
        <div className="relative m-0 overflow-hidden">
          <div>
            <MenuCreateEditKukan addr={ userAddr } data={ importData } kouzou={ kouzouData } />
            <KukanManagedClass data={ importData } kouzou={ kouzouData } />
          </div>
        </div>
      </>
      
    );
  } else {
    return (
      <div id="waiting" className="w-full h-fit bg-gradient-to-t from-black via-blue-900 to-white">
        <div className="h-screen flex justify-center items-center py-4">
          <div className="mt-6 text-xl text-center font-medium">
            <div className="mb-4">
              <div className="p-4 border-2 border-dashed border-white rounded-lg">
                <div className="mb-8 p-4 border-2 border-dashed border-white rounded-lg">
                  <p className="w-full text-left ">IMPORT KUKAN DATA ?</p>
                  <textarea id="import_data" onChange={ checkImportData } 
                    className="select-none w-full mb-4 px-2 py-4 rounded-lg border-2 border-gray-800 text-gray-800"
                    name="import_data"
                    placeholder="ENTER KUKAN DATA (.json)"
                  />
                  <ImportKouzouComponent />
                </div>
                <div className="text-white">
                  <button onClick={ init } className="mb-4 rounded-lg shadow-md w-full py-8 bg-gradient-to-r from-gray-900 via-blue-900 to-white hover:bg-opacity-100 hover:scale-105 font-black bg-opacity-80 transition duration-[250ms] ease-out">
                    IMPORT KUKAN DATA
                  </button>
                </div>
                <p className="my-4">OR</p>
                <div className="text-white">
                  <button onClick={ init } className="mb-4 rounded-lg w-full py-8 bg-gradient-to-r from-white via-blue-900 to-gray-900 hover:bg-opacity-100 hover:scale-105 font-black bg-opacity-80 transition duration-[250ms] ease-out">
                    CREATE NEW KUKAN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const LoadingWindow = () => {
    return(
    <div id="waiting" className="absolute z-50 animate-pulse w-full h-fit bg-gradient-to-t from-black via-blue-900 to-white">
      <div className="h-screen flex justify-center items-center">
        <div className="mt-6 text-xl text-center font-medium text-white">
          <p className="">...読込み中</p>
          <p className="">...loading</p>
          <p className="mt-2">上手く読み込めない場合は再度リロードしてみて下さい。</p>
        </div>
      </div>
    </div>
  );
}

const ImportKouzouComponent = () => {
  
  function importKouzou() {
    const fileInput = document.getElementById("kouzouInput");
    fileInput?.click();
  }

  function checkFile(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.currentTarget.files;
    if(file.length > 0) {
      document.getElementById("selectedFileName").textContent = file[0].name + " " + Emoji.checkEmoji;
      kouzouData = file[0];
    } else {
      kouzouData = null;
      alert("ファイルを選択してください");
    }
  }

  return(
    <div>
      <p className="text-left">IMPORT A KOUZOU FILE ? (.glb)</p>
      <button onClick={ importKouzou }
        className="mb-1 rounded-lg border-2 border-gray-800 shadow-md w-full py-4 font-bold text-gray-900 bg-white hover:bg-opacity-100 hover:scale-105 transition duration-[250ms] ease-out"
      >
        SELECT FILE
      </button>
      <input id="kouzouInput" onChange={(e) => checkFile(e)} type="file" accept=".glb" className="hidden" />
      <p id="selectedFileName" className="my-2 text-black" />
    </div>
  )
}

export default Edit;