import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ITEMS_KUKAN } from "./InitItemsKukan";

// 衝突を検知するオブジェクト
export const collidersObj: any = [];
export const manager = new THREE.LoadingManager();

let canvases: THREE.Group;
let posArrow = new THREE.Group();


const OBJ_ROOM_SIMPLE = "/model/room_simple01.glb";
const OBJ_FLOOR = "/model/floor01.glb";

const OBJ_ROOM_01 = "/model/room09.glb";
const OBJ_ROOM_02 = "/model/room_hakoniwa.glb";
const OBJ_ROOM_03 = "/model/room_Lloyd01.glb";
const OBJ_ISLAND = "/model/island.glb";

const IMAGE_DEFAULT_CANVAS = "/images/default_canvas.png";
const IMAGE_MOVED_ITEM = "/images/moved_item.png";

/**
 * CREATE NEW KUKAN
 */
export function ObjectLoaderCreateKukan(scene: THREE.Scene, kouzou_data: File) {
  createFloor(scene);
  createObjects(scene);
  // createSkyBox();
  // createStar();
  craeteNewKukan(scene, kouzou_data);
  createArrows(scene);
}

/**
 * EDIT KUKAN
 */
export async function ObjectLoaderEditKukanFromData(scene: THREE.Scene, import_data: any, kouzou_data: File) {
  
  createFloor(scene);
  createObjects(scene);
  // createSkyBox();
  // createStar();
  // craeteNewKukan(scene);
  const itemList = await loardPictureFromData(import_data);
  createEditKukanFromData(import_data, itemList, scene, kouzou_data);
  createArrows(scene);

  // createIsland();
  // createSkyBox();
  // createStar();
  // createArrows();
}

// /**
//  * MY KUKAN
//  */
// export async function ObjectLoaderMyKukanFromData() {
//   createIsland();
//   createSkyBox();
//   createStar();
//   const itemList = await loardPictureFromData(ITEMS_KUKAN.currentKukanData);
//   createMyKukanFromData(itemList);
// }

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

////////////////////////////////////////////////////////////////
// Island
////////////////////////////////////////////////////////////////
function createIsland() {
  const loader = new GLTFLoader();
  const nowTime = new Date(); // 現在日時を得る
  const nowHour = nowTime.getHours(); // 時間を抜き出す
  let ASSETS_PATH = OBJ_ISLAND;
  loader.load(ASSETS_PATH, (gltf) => {
    gltf.scene.name = "island";
    gltf.scene.scale.set(1, 1, 1);
    gltf.scene.position.set(0, 0, 0);
    ITEMS_KUKAN.scene.add(gltf.scene);
    ITEMS_KUKAN.colliders.push(gltf.scene);
  });
}

////////////////////////////////////////////////////////////////
// Floor
////////////////////////////////////////////////////////////////
function createFloor(scene) {
  const loader = new GLTFLoader();
  let ASSETS_PATH = OBJ_FLOOR;
  loader.load(ASSETS_PATH, (gltf) => {
    gltf.scene.traverse((object) => {
      object.receiveShadow = true;
      object.castShadow = true;
    })
    gltf.scene.name = "floor";
    gltf.scene.scale.set(1, 1, 1);
    gltf.scene.position.set(0, 0, 0);
    scene.add(gltf.scene);
    // ITEMS_KUKAN.colliders.push(gltf.scene);
  });
}

function createObjects(scene) {
  // SkyBox
  const skyBoxGeo = new THREE.SphereGeometry(5, 32, 32);
  const skyBoxMaterial = new THREE.MeshLambertMaterial({ color: 0xFEFEFE, side: THREE.DoubleSide, });
  const skyBox = new THREE.Mesh(skyBoxGeo, skyBoxMaterial);
  skyBox.position.set(0, 0, 0);
  skyBox.scale.set(20, 20, 20);
  scene.add(skyBox);
}

////////////////////////////////////////////////////////////////
// SkyBox
////////////////////////////////////////////////////////////////
function createSkyBox() {
  const loader = new GLTFLoader();
  const nowTime = new Date(); // 現在日時を得る
  const nowHour = nowTime.getHours(); // 時間を抜き出す
  let ASSETS_PATH = "/model/skybox13.glb";
  if(nowHour >= 18 || 5 >= nowHour) {
    ASSETS_PATH = "/model/skybox13.glb";
  } else {
    ASSETS_PATH = "/model/skybox15.glb";
  }
  loader.load(ASSETS_PATH, (gltf) => {
    gltf.scene.name = "skybox";
    gltf.scene.scale.set(3, 3, 3);
    gltf.scene.position.set(0, 0, 0);
    ITEMS_KUKAN.scene.add(gltf.scene);
    ITEMS_KUKAN.colliders.push(gltf.scene);
  });
}
////////////////////////////////////////////////////////////////
// Star
////////////////////////////////////////////////////////////////
let starModel;
function createStar() {
  const numX = getRandomInt(0, 360);
  const numY = getRandomInt(0, 360);
  const numZ = getRandomInt(0, 360);
  const loader = new GLTFLoader();
  const ASSETS_PATH = "/model/star01.glb";
  loader.load(ASSETS_PATH, (gltf) => {
    gltf.scene.name = "stars";
    gltf.scene.scale.set(3, 3, 3);
    gltf.scene.position.set(0, 0, 0);
    gltf.scene.rotation.set(numX, numY, numZ);
    starModel = gltf.scene;
    ITEMS_KUKAN.scene.add(gltf.scene);
  });
}

export function moveStars() {
  setTimeout(() => {
    starModel.rotation.z -= 0.00005;
    starModel.rotation.y -= 0.0001;
  }, 1000);
}

function craeteNewKukan(scene: THREE.Scene, kouzou_data: File) {
  const loaderNormal = new THREE.TextureLoader();
  let texture = loaderNormal.load(IMAGE_DEFAULT_CANVAS);
  texture.rotation = Math.PI/2;
  texture.center = new THREE.Vector2(0.5, 0.5);
  const loader = new GLTFLoader(manager);
  const ASSETS_PATH = kouzou_data != null ? kouzou_data : OBJ_ROOM_SIMPLE;

  if(typeof ASSETS_PATH === "string") {
    
    loader.load(ASSETS_PATH, (gltf) => {  
      gltf.scene.traverse((child: any) => {
        craeteNewKukanLoadKouzou(child, texture);
      });
      
      gltf.scene.name = "museum_01";
      canvases = gltf.scene;
      gltf.scene.scale.set(1, 1, 1);
      gltf.scene.position.set(0, -1, 0);
      scene.add(gltf.scene);
      // ITEMS_KUKAN.colliders.push(gltf.scene);
    });

  } else {
    loader.load(URL.createObjectURL(ASSETS_PATH), (gltf) => {
      gltf.scene.traverse((child: any) => {
        craeteNewKukanLoadKouzou(child, texture);
      });
      
      gltf.scene.name = "museum_01";
      canvases = gltf.scene;
      gltf.scene.scale.set(1, 1, 1);
      gltf.scene.position.set(0, -1, 0);
      scene.add(gltf.scene);
      // ITEMS_KUKAN.colliders.push(gltf.scene);
    });
  }
  
}

function craeteNewKukanLoadKouzou(child: any, texture: THREE.Texture) {
  
  if(child.name.includes("Guide")) child.visible = false;
  if(!child.isMesh) return;

  child.material.transparent = true;
  child.material.polygonOffset = true;
  child.material.polygonOffsetFactor = -1;
  child.material.polygonOffsetUnits = -1;
  // child.material.alphaToCoverage = true; // 追加
  // child.material.opacity = .6;
  // console.log(child.material);

  if(child.material.name.slice(0, 7) !== "Picture") return;
  child.material.map = texture;
  
  
  // カード初期化
  ITEMS_KUKAN.selectedSpcaeItemsArray.push([
    child.material.name,
    "none"
  ]);
}

/**
 * リターンは Picture_Wrapper の 状態
 * @param picName 
 * @returns 
 */
export function getItemPropAndSetPosArrow(picName: string) {
  // child.parent === "Picture_Wrapper"
  let data;
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    
    data = {
      scale: child.parent.scale.z,
      posX: child.parent.position.x,
      posY: child.parent.position.y,
      posZ: child.parent.position.z,
      rotX: child.parent.rotation.x,
      rotY: child.parent.rotation.y,
      rotZ: child.parent.rotation.z,
    }
    setPosArrow(child);
  });
  return data;
}

export function setPosArrow(child: any) {
  posArrow.position.copy(child.parent.getWorldDirection(new THREE.Vector3()));
  posArrow.position.copy(child.parent.getWorldPosition(new THREE.Vector3()));
}

export function displayPosArrow(itemGrabState: boolean) {
  if(itemGrabState) {
    posArrow.visible = true;
  } else {
    posArrow.visible = false;
    posArrow.position.set(0, -50, 0);
  }
}

function createArrows(scene) {
  const loader = new GLTFLoader(manager);
  const ASSETS_PATH = "/model/dir_arrows.glb";

  loader.load(ASSETS_PATH, (gltf) => {
    gltf.scene.traverse((child: any) => {
      if(!child.isMesh) return;
    });
    gltf.scene.scale.set(1, 1, 1);
    gltf.scene.position.set(0, 0, 0);
    gltf.scene.name = "Arrows";
    gltf.scene.visible = false;
    posArrow = gltf.scene;
    
    scene.add(gltf.scene);
    // ITEMS_KUKAN.colliders.push(gltf.scene);
  });
}

function createEditKukanFromData(import_data:any, itemList: any[], scene: THREE.Scene, kouzou_data: File) {
  const loader = new GLTFLoader(manager);
  const KUKAN_PATH = kouzou_data != null ? kouzou_data : OBJ_ROOM_SIMPLE;
  // const KUKAN_PATH = OBJ_ROOM_SIMPLE;
  // const KUKAN_PATH = selectedKukanPass();
  
  // selectedSpcaeItemsArray を初期化。画像が無い所は"none"が入る
  ITEMS_KUKAN.selectedSpcaeItemsArray.splice(0);
  import_data.kukan_details.kukan[0].picture_data.forEach((r) => {
    
    // カードデータ反映
    ITEMS_KUKAN.selectedSpcaeItemsArray.push([
      r.picture_name,
      r.asset_name
    ]);
  });

  if(typeof KUKAN_PATH === "string") {
    loader.load(KUKAN_PATH, (gltf) => {
      gltf.scene.traverse((child: any) => {
  
        if(child.name.includes("Guide")) child.visible = false;
  
        if(!child.isMesh) return;
        if(child.material.name === "") return; // Arrow_Axisがグループなのでmaterialなし
  
        /**
         * item[0] picture_name, item[1] asset_name, item[2] picture_state, item[3] cardLink,
         */
        itemList.find((item) => {
          if(item[0] !== child.material.name) return;
          
          let cardLink = item[3];
          let texture: THREE.Texture;
  
          if(cardLink.slice(-3) === "mp4") {
            const posterLink = cardLink.replace(".mp4", "l.png");
            const video = document.createElement("video");
            video.setAttribute("id", "video_0");
            video.src = cardLink;
            video.crossOrigin = "Anonymous";
            video.poster = posterLink;
            video.loop = true;
            video.muted = true;
            video.load();
            video.play();
            
            texture = new THREE.VideoTexture(video);
          } else if(cardLink === "none") {
            const loaderNormal = new THREE.TextureLoader();
            texture = loaderNormal.load(IMAGE_DEFAULT_CANVAS);
          } else if(cardLink === "notOwn") {
            const loaderNormal = new THREE.TextureLoader();
            texture = loaderNormal.load(IMAGE_MOVED_ITEM);
          } else {
            const loaderNormal = new THREE.TextureLoader();
            texture = loaderNormal.load(cardLink);
          }
          texture.rotation = Math.PI/2;
          texture.center = new THREE.Vector2(0.5, 0.5);
  
          child.material.map = texture;
          child.material.transparent = true;
          child.material.polygonOffset = true;
          child.material.polygonOffsetFactor = -1;
          child.material.polygonOffsetUnits = -1;
  
          child.parent.position.set(item[2].position.x, item[2].position.y, item[2].position.z);
          child.parent.rotation.set(item[2].rotation.x, item[2].rotation.y, item[2].rotation.z);
          child.parent.scale.set(item[2].scale.x, item[2].scale.y, item[2].scale.z);
        });
      });
      canvases = gltf.scene;
      gltf.scene.name = import_data.kukan_details.kukan[0].kukan_type;
      gltf.scene.scale.set(1, 1, 1);
      gltf.scene.position.set(0, 0, 0);
      scene.add(gltf.scene);
      // ITEMS_KUKAN.colliders.push(gltf.scene);
    });
  } else {
    loader.load(URL.createObjectURL(KUKAN_PATH), (gltf) => {
      
      gltf.scene.traverse((child: any) => {
  
        if(child.name.includes("Guide")) child.visible = false;
  
        if(!child.isMesh) return;
        if(child.material.name === "") return; // Arrow_Axisがグループなのでmaterialなし
  
        /**
         * item[0] picture_name, item[1] asset_name, item[2] picture_state, item[3] cardLink,
         */
        itemList.find((item) => {
          if(item[0] !== child.material.name) return;
          
          let cardLink = item[3];
          let texture: THREE.Texture;
  
          if(cardLink.slice(-3) === "mp4") {
            const posterLink = cardLink.replace(".mp4", "l.png");
            const video = document.createElement("video");
            video.setAttribute("id", "video_0");
            video.src = cardLink;
            video.crossOrigin = "Anonymous";
            video.poster = posterLink;
            video.loop = true;
            video.muted = true;
            video.load();
            video.play();
            
            texture = new THREE.VideoTexture(video);
          } else if(cardLink === "none") {
            const loaderNormal = new THREE.TextureLoader();
            texture = loaderNormal.load(IMAGE_DEFAULT_CANVAS);
          } else if(cardLink === "notOwn") {
            const loaderNormal = new THREE.TextureLoader();
            texture = loaderNormal.load(IMAGE_MOVED_ITEM);
          } else {
            const loaderNormal = new THREE.TextureLoader();
            texture = loaderNormal.load(cardLink);
          }
          texture.rotation = Math.PI/2;
          texture.center = new THREE.Vector2(0.5, 0.5);
  
          child.material.map = texture;
          child.material.transparent = true;
          child.material.polygonOffset = true;
          child.material.polygonOffsetFactor = -1;
          child.material.polygonOffsetUnits = -1;
  
          child.parent.position.set(item[2].position.x, item[2].position.y, item[2].position.z);
          child.parent.rotation.set(item[2].rotation.x, item[2].rotation.y, item[2].rotation.z);
          child.parent.scale.set(item[2].scale.x, item[2].scale.y, item[2].scale.z);
        });
      });
      canvases = gltf.scene;
      gltf.scene.name = import_data.kukan_details.kukan[0].kukan_type;
      gltf.scene.scale.set(1, 1, 1);
      gltf.scene.position.set(0, 0, 0);
      scene.add(gltf.scene);
      // ITEMS_KUKAN.colliders.push(gltf.scene);
    });
  }
  
}

/**
 * カードがgifの場合にアニメーションか一枚絵かチェックする。
 * アニメーションの場合はmp4に変換。
 * 一枚絵の場合はjpgに変換。
 * @param cardLink 
 * @returns 
 */
async function gifToOptimizedFormat(cardLink: string): Promise<string> {
  let checkURL = cardLink;

  // cidからgifかどうか判定
  const cid = checkURL.substring(checkURL.length - 59);
  ITEMS_KUKAN.gifCardList.find((el) => {
    if(el === cid) {
      checkURL = checkURL + ".gif";
    } 
  })
  return checkURL;

  // if(checkURL.slice(-3) === "gif") {
  //   const reqCardLinkMP4 = checkURL.replace(".gif", ".mp4");
  //   await fetch(reqCardLinkMP4).then((res) => {
  //     if(res.status === 400) checkURL = checkURL.replace(".gif", ".jpg");
  //     else checkURL = checkURL.replace(".gif", ".mp4");
  //   })
  //   return checkURL;
  // } else {
  //   return checkURL;
  // }
}

export async function changeCanvasPicture(cardLink: string, selectedCanvasName: string) {

  let texture: THREE.Texture;

  const optURL = await gifToOptimizedFormat(cardLink);

  if(optURL.slice(-3) === "mp4") {
    const video = document.createElement("video");
    video.setAttribute("id", "video_0");
    video.src = optURL;
    video.crossOrigin = "Anonymous";
    video.loop = true;
    video.load();
    video.play();
    texture = new THREE.VideoTexture(video);
  } else {
    const loaderNormal = new THREE.TextureLoader();
    texture = loaderNormal.load(optURL);
  }

  texture.rotation = Math.PI/2;
  texture.center = new THREE.Vector2(0.5, 0.5);
  
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name === selectedCanvasName) {
      child.material.map = texture;
    }
  });
}

export function setPicturesState(picture_name: string) {
  let data: pictureStateType;
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name === picture_name) {
      data = {
        position: {
          x: child.parent.position.x,
          y: child.parent.position.y,
          z: child.parent.position.z,
        },
        rotation: {
          x: child.parent.rotation.x,
          y: child.parent.rotation.y,
          z: child.parent.rotation.z,
        },
        scale: {
          x: child.parent.scale.x,
          y: child.parent.scale.y,
          z: child.parent.scale.z,
        }
      }
    }
  });
  return data;
}

async function loardPictureFromData(import_data: any) {
  const itemList = [];
  // console.table(typeof(JSON.parse(import_data)));
  // console.table(JSON.parse(import_data));
  // const parseData = JSON.parse(import_data);
  const resisteredPictureData: pictureDataType = import_data.kukan_details.kukan[0].picture_data;

  // TO DO
  // pictureData ga null no baai

  for(let i = 0; i < resisteredPictureData.length; i++) {
    let cardURL: string;
    if(resisteredPictureData[i].asset_name === "none") {
      cardURL = "none";
      itemList.push([
        resisteredPictureData[i].picture_name,
        resisteredPictureData[i].asset_name,
        resisteredPictureData[i].picture_state,
        cardURL,
      ]);
    } else {
      const result = ITEMS_KUKAN.monacardInfoCurrentlyOwned.find((ownedData) => {
        if(ownedData.assetName === resisteredPictureData[i].asset_name) return ownedData.imgur_url_original;
      });
      if(result != undefined) {
        cardURL = await gifToOptimizedFormat(result.imgur_url_original);
      } 
      else cardURL = "notOwn";

      itemList.push([
        resisteredPictureData[i].picture_name,
        resisteredPictureData[i].asset_name,
        resisteredPictureData[i].picture_state,
        cardURL
      ]);
    }
  }
  return itemList;
}

function loardPictureFromDataForScreenshot(selectedPictureData: pictureDataType) {
  const itemListForScreenshot = [];
  for(let i = 0; i < selectedPictureData.length; i++) {
    let cardURL: string;
    if(selectedPictureData[i].asset_name === "none") {
      cardURL = "none";
      itemListForScreenshot.push([ selectedPictureData[i].picture_name, selectedPictureData[i].asset_name, cardURL ]);
    } else {
      const result = ITEMS_KUKAN.monacardInfoCurrentlyOwned.find((ownedData) => {
        if(ownedData.assetName === selectedPictureData[i].asset_name) return ownedData.imgur_url_original;
      });
      if(result != undefined) cardURL = result.imgur_url_original;
      else cardURL = "notOwn";
      itemListForScreenshot.push([ selectedPictureData[i].picture_name, selectedPictureData[i].asset_name, cardURL ]);
    }
  }
  return itemListForScreenshot;
}

const saveState = [];

/**
 * for screenshot
 * @param picture_data 
 */
export async function hideNotUsedCanvas(picture_data) {
  const itemListForScreenshot = loardPictureFromDataForScreenshot(picture_data);
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    itemListForScreenshot.find((r) => {
      if(r[0] != child.material.name) return;
      if(r[2] === "none" || r[2] === "notOwn") {
        // asset_nameが"none"の場合はcanvasを非表示
        child.parent.visible = false;
        saveState.push(child.material.name);
      }
    });
  });
  posArrow.visible = false;
}

/**
 * for screenshot
 * @param picture_data 
 */
export async function displayNotUsedCanvas() {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    saveState.find((r) => {
      if(r != child.material.name) return;
      child.parent.visible = true;
    });
  });
  saveState.length = 0;
}

// function createMyKukanFromData(itemList: any[]) {
//   const loader = new GLTFLoader(manager);
//   const KUKAN_PATH = selectedKukanPass();

//   loader.load(KUKAN_PATH, (gltf) => {
//     gltf.scene.traverse((child: any) => {

//       if(child.name.includes("Frame")) {
//         // 一度フレームを全部消す
//         child.visible = false;
        
//         ITEMS_KUKAN.selectedFrameArray.find((frameState => {
//           // frame visible
//           if(frameState[2] === child.name) child.visible = true;
//           // frame color
//           const frameName = "Frame" + frameState[0].slice(-3); // フレームの
//           if(!child.isMesh) return; // ObjectがGroupの場合がある
//           if(child.material.name !== frameName) return;
//           child.material.color.setHex(frameState[1]);
//         }));
//       }

//       if(!child.isMesh) return;
//       if(child.material.name.includes("wall")) {
//         child.receiveShadow = false;
//         child.castShadow = false;
//       }

//       if(child.material.name.includes("Celling")) {
//         child.castShadow = false;
//       }
      
//       itemList.find((item) => {
        
//         if(item[0] != child.material.name) return;

//         // asset_nameが"none"の場合はcanvasを非表示
//         if(item[1] === "none") {
//           child.parent.visible = false;
//           child.parent.material.dispose();
//           child.parent.geometry.dispose();
//         }
//         child.castShadow = false;

//         let cardLink = item[3];
//         let texture: THREE.Texture;

//         // cardLink"none"の場合はpictureを非表示
//         if(cardLink.slice(-3) === "mp4") {
//           const posterLink = cardLink.replace(".mp4", "l.png");
//           // cardLink = cardLink.replace(".gif", ".mp4");
//           const video = document.createElement("video");
//           video.setAttribute("id", "video_0");
//           video.src = cardLink;
//           video.crossOrigin = "Anonymous";
//           video.poster = posterLink;
//           video.loop = true;
//           video.muted = true;
//           video.load();
//           video.play();
//           texture = new THREE.VideoTexture(video);
//         } else if(cardLink === "none") {
//           child.material.visible = false;
//         } else if(cardLink === "notOwn") {
//           const loaderNormal = new THREE.TextureLoader();
//           texture = loaderNormal.load(IMAGE_MOVED_ITEM);
//         } else {
//           const loaderNormal = new THREE.TextureLoader();
//           texture = loaderNormal.load(cardLink);
//         }
//         if(cardLink != "none") {
//           texture.rotation = Math.PI/2;
//           texture.center = new THREE.Vector2(0.5, 0.5);
          
//           child.material.map = texture;
//           child.material.transparent = true
//           child.material.polygonOffset = true;
//           child.material.polygonOffsetFactor = -1;
//           child.material.polygonOffsetUnits = -1;

//           child.parent.position.set(item[2].position.x, item[2].position.y, item[2].position.z);
//           child.parent.rotation.set(item[2].rotation.x, item[2].rotation.y, item[2].rotation.z);
//           child.parent.scale.set(item[2].scale.x, item[2].scale.y, item[2].scale.z);
//         }
//       });
//     });

//     gltf.scene.scale.set(1, 1, 1);
//     gltf.scene.position.set(0, 0, 0);
//     ITEMS_KUKAN.scene.add(gltf.scene);
//     ITEMS_KUKAN.colliders.push(gltf.scene);

//   });
// }

export function editPictureSizeObj(picName: string, val: number) {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    child.parent.scale.x = val; // height
    child.parent.scale.y = val; // height
    child.parent.scale.z = val; // width
  });
}

export function editPicturePositionObjSlider(picName: string, val: number, dir: string) {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    if(dir === "pos_x_slider") posArrow.position.x += val;
    else if(dir === "pos_y_slider") posArrow.position.y += val;
    else if(dir === "pos_z_slider") posArrow.position.z += val;

    child.parent.position.copy(posArrow.position);
  });
}

export function editPicturePositionObjNumber(picName: string, val: number, dir: string) {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    if(dir === "pos_x_number") posArrow.position.x = val;
    else if(dir === "pos_y_number") posArrow.position.y = val;
    else if(dir === "pos_z_number") posArrow.position.z = val;
    child.parent.position.copy(posArrow.position);
  });
}

export function editPictureRotateObjSlider(picName: string, val: number, dir: string) {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    if(dir === "rot_dir_x") child.parent.rotation.x = val;
    else if(dir === "rot_dir_y") child.parent.rotation.y = val;
    else if(dir === "rot_dir_z") child.parent.rotation.z = val;
  });
}

export function editPictureRotateObjNumberInput(picName: string, val: number, dir: string) {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    if(dir === "rot_dir_x") child.parent.rotation.x = val;
    else if(dir === "rot_dir_y") child.parent.rotation.y = val;
    else if(dir === "rot_dir_z") child.parent.rotation.z = val;
  });
}

export function editPictureRotateObjNumber(picName: string, val: number, dir: string) {
  canvases.traverse((child: any) => {
    if(!child.isMesh) return;
    if(child.material.name !== picName) return;
    if(dir === "rot_dir_x") child.parent.rotation.x += val;
    else if(dir === "rot_dir_y") child.parent.rotation.y += val;
    else if(dir === "rot_dir_z") child.parent.rotation.z += val;
  });
}

// export function changeFrameColor(picName: string, color: string) {
//   const frameName = "Frame" + picName.slice(-3);
//   canvases.traverse((child: any) => {
//     if(!child.isMesh) return;
//     if(child.material.name === frameName) {
//       child.material.color.setHex(color);
//     }
//   });
// }

// export function changeFrameType(picName: string, selectedFrameName: string) {
//   const frameNum = picName.slice(-2);
//   canvases.traverse((child: any) => {
//     // フレーム以外はリターン
//     // フレームの後ろ2桁でPictureNumberを判別
//     if(!child.name.includes("Frame")) return;
//     if(child.name.slice(-2) !== frameNum) return;
//     if(selectedFrameName === child.name) {
//       // Frame は Frame101, Frame201, Frame301 の形式
//       child.visible = true;
//     } else if(selectedFrameName !== child.name) {
//       child.visible = false;
//     }
//   });
// }

export function changeKukanType(selectedKukanType: string) {

  if(canvases.name === selectedKukanType) return;
  displayPosArrow(false);

  const loaderNormal = new THREE.TextureLoader();
  let texture = loaderNormal.load(IMAGE_DEFAULT_CANVAS);
  texture.rotation = Math.PI/2;
  texture.center = new THREE.Vector2(0.5, 0.5);
  const loader = new GLTFLoader(manager);

  let SELECTED_ASSETS_PATH = "";
  switch(selectedKukanType) {
    case "museum_01":
      ITEMS_KUKAN.selectedKukanType = "museum_01";
      SELECTED_ASSETS_PATH = OBJ_ROOM_01;
      break;
    case "museum_02":
      ITEMS_KUKAN.selectedKukanType = "museum_02";
      SELECTED_ASSETS_PATH = OBJ_ROOM_02;
      break;
    case "museum_03":
      ITEMS_KUKAN.selectedKukanType = "museum_03";
      SELECTED_ASSETS_PATH = OBJ_ROOM_03;
      break;
  }

  loader.load(SELECTED_ASSETS_PATH, (gltf) => {
    gltf.scene.traverse((child: any) => {
      child.receiveShadow = true;
      child.castShadow = true;
      if(!child.isMesh) return;
      if(child.material.name.slice(0, 7) !== "Picture") return;
      child.material.map = texture;
      child.material.transparent = true
      child.material.polygonOffset = true;
      child.material.polygonOffsetFactor = -1;
      child.material.polygonOffsetUnits = -1;

      ITEMS_KUKAN.selectedSpcaeItemsArray.pop(); // 初期化のために入れたnullを削除する

      // カード初期化
      ITEMS_KUKAN.selectedSpcaeItemsArray.push([
        child.material.name,
        "none"
      ]);
      
    });

    // 選択したルーム以外は削除
    ITEMS_KUKAN.scene.children.find((obj) => {
      if(obj.name.slice(0, 6) !== "museum") return;
      if(ITEMS_KUKAN.selectedKukanType !== obj.name) {
        obj.removeFromParent();
      } 
    });
  
    const newCollidersArray = ITEMS_KUKAN.colliders.filter(r => r.name.slice(0, 6) !== "museum");
    ITEMS_KUKAN.colliders.splice(0);
    newCollidersArray.forEach(r => ITEMS_KUKAN.colliders.push(r));
    
    gltf.scene.name = selectedKukanType;
    gltf.scene.scale.set(1, 1, 1);
    gltf.scene.position.set(0, 0, 0);
    canvases = gltf.scene;
    ITEMS_KUKAN.scene.add(gltf.scene);
    ITEMS_KUKAN.colliders.push(gltf.scene);
  });
}

function selectedKukanPass() {
  let KUKAN_PATH = "";
  switch(ITEMS_KUKAN.selectedKukanType) {
    case "museum_01":
      KUKAN_PATH = OBJ_ROOM_01;
      break;
    case "museum_02":
      KUKAN_PATH = OBJ_ROOM_02;
      break;
    case "museum_03":
      KUKAN_PATH = OBJ_ROOM_03;
      break;
  }
  return KUKAN_PATH;
}
