import { ITEMS } from "../components/InitItems";

const now = new Date();
const nowTime = now.getTime();
const nowTimeToTimestamp = Math.floor( nowTime / 1000 );

export async function checkServerResponse() {
  let serverURL = "";
  const servers = [
    "https://wallet.monaparty.me/_api",
    "https://monapa.electrum-mona.org/_api",
  ]
  for (let url of servers) {
    const res = await fetch(url).then((r): any => { return r }).catch(() => { return "ERROR" });
    if(res.status === 200) {
      serverURL = url;
      break;
    }
  }
  return serverURL;
}

const data = {
  "jsonrpc":"2.0",
  "id":0,
  "method":"get_transaction_stats",
  "params":{
    // "start_ts": 1563721200, // 2019/07/22/9:00 resultALL: 389441
    "start_ts": 1509462000, // 2017/11/01/9:00 resultALL: 471936
    "end_ts": nowTimeToTimestamp,
  }
}

// リクエストは一回でよく、そこから算出方法を複数用意する


// ☆１か月間の集計
// 現在時刻をユニックスタイムに　→　月を確認　→　当月の初めから
// 
// ☆１年間の集計
// 現在時刻をユニックスタイムに　→　年を確認　→　一か月間のtxをリクエスト
// 
// 
// START    1509462000 // 2017/11/01/9:00
// 1  月 30 2592000
// 2  月 28 2419200
// 3  月 31 2678400
// 4  月 30 2592000
// 5  月 31 2678400
// 6  月 30 2592000
// 7  月 31 2678400
// 8  月 31 2678400
// 9  月 30 2592000
// 10 月 30 2592000
// 11 月 30 2592000
// 12 月 31 2678400
// 
// 

// TX Stats Info
export async function getTxState(): Promise<any> {
  const result = await fetch(ITEMS.monaparty_server_url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
  .then((r): any => {
    return r.json();
  })
  .then((r) => {
    //
    // MEMO RETURN DATA //
    //
    // {
    //   "jsonrpc": "2.0",
    //   "id": 0,
    //   "result": {
    //     [0]: {
    //       "data": {
    //         [0]: { 1week
    //           [0]: [blockheight, amount],
    //           [1]: [blockheight, amount],
    //           [...]: [blockheight, amount],
    //           [99]: [blockheight, amount],
    //         },
    //         [1]: { 1week
    //           [99]: [blockheight, amount],
    //           [100]: [blockheight, amount],
    //           [...]: [blockheight, amount],
    //           [199]: [blockheight, amount],
    //         },
    //         [...],
    //       },
    //       "name": "send"
    //     },
    //     [1]: {
    //       "data": {
    //         [0]: {
    //           [0]: [blockheight, amount],
    //           [1]: [blockheight, amount],
    //           [...]: [blockheight, amount],
    //           [99]: [blockheight, amount],
    //         },
    //         [1]: {
    //           [99]: [blockheight, amount],
    //           [100]: [blockheight, amount],
    //           [...]: [blockheight, amount],
    //           [199]: [blockheight, amount],
    //         },
    //         [...],
    //       },
    //       "name": "issuances"
    //     },
    //   }
    // }
    
    // console.table(r.result);

    return r.result;
  })
  .catch((e) => {
    console.log(e);
  });
  return result;
}

type txQuantityType = {
  totalQuantity: number,
  oneDayQuantity: number,
  sevenDaysQuantityTotal: number,
  monthQuantity: number,
  sevenDaysQuantityGraph: Array<[string, number]>,
}

// modify the received tx amount
export async function txQuantityModify(result: any): Promise<txQuantityType>  {
  let txQuantity: txQuantityType = {
    totalQuantity: 0,
    oneDayQuantity: 0,
    sevenDaysQuantityTotal: 0,
    monthQuantity: 0,
    sevenDaysQuantityGraph: [["", 0], ["", 0], ["", 0], ["", 0], ["", 0], ["", 0], ["", 0], ["", 0], ["", 0], ["", 0]]
  }

  result.forEach(r => {
    // mpchainでは22/07/28時点で「dispenses, dispensers, assetgroups, destructions」が無く、「Bet」が余分に含まれている。

    r.data.forEach(rr => {
      // {"sweeps", "broadcasts", "dividends", "sends", "orders", "cancels", "dispenses", "destructions", "issuances", "dispensers", "assetgroups"}
      // のカウント
      // rr[0] = timestamp
      // rr[1] = amount
      txQuantity.totalQuantity += rr[1];
    });

    // TX amount of one day ago
    txQuantity.oneDayQuantity += r.data.at(-1)[1];

    // TX amount of seven days ago
    for(let m = 0; m < 7; m++) {
      txQuantity.sevenDaysQuantityTotal += r.data.at(-1-m)[1];
    }

    // TX amount of a month ago
    for(let m = 0; m < 30; m++) {
      txQuantity.monthQuantity += r.data.at(-1-m)[1];
    }

    // TX amount of seven days ago for graph
    for(let m = 0; m < 10; m++) {
      // quantity
      txQuantity.sevenDaysQuantityGraph[m][1] += r.data.at(-1-m)[1] / 4;
    }

  });

  // TX timestamp of seven days ago for graph
  for(let m = 0; m < 10; m++) {
    // timestamp
    const a = new Date(result[0].data.at(-1 - m)[0]);
    // txQuantity.sevenDaysQuantityGraph[m][0] = a.toLocaleDateString("ja-JP").slice(5); // 2022/7/22 → 7/22
    // txQuantity.sevenDaysQuantityGraph[m][0] = a.toLocaleDateString("ja-JP").slice(2); // 2022/7/22 → 22/7/22
    txQuantity.sevenDaysQuantityGraph[m][0] = a.toLocaleDateString("ja-JP"); // 2022/7/22
  }

  return txQuantity;

}