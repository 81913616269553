import * as THREE from "three";
import * as CANNON from 'cannon-es'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { World } from "cannon-es";
import { getJoinedMonacardList, getMonacardInfoMany, getAssetsBalancesElectrum } from "./Mpurse";
import { ITEMS } from '../components/InitItems';
import { unescapeProcess } from './Utils';

const FIRST_ISLAND_POS_X = -1500;
const FIRST_ISLAND_POS_Y = 500;
const FIRST_ISLAND_POS_Z = 1500;

const cannonWorld = new World;
// const collidersObj: [THREE.Group | THREE.Mesh | THREE.Object3D] = null;
// const cardColliderMeshes: any = [];
const cardBodies: any = [];

let littleMonaBody = {
  "cannon": null,
  "object": null
};
let littleMonaMesh: any;
let littleMonaMixer: THREE.AnimationMixer;
let littleMonaClips: THREE.AnimationClip[];
let littleMonaAction: THREE.AnimationAction;
let littleMonaCurrentAnim: string;

// let firstIslandBodyThree = new Object3D;
let firstIslandBodyCannon: CANNON.Body;
let firstIslandMesh: any;

let monacardInfo = [];

const solver = new CANNON.GSSolver();

// Create a slippery material (friction coefficient = 0.0)
const physicsMaterial = new CANNON.Material('physics')
const physics_physics = new CANNON.ContactMaterial(physicsMaterial, physicsMaterial, {
  friction: 0.0,
  restitution: 0.3,
})

export class PysicsObj {

  constructor() {
    cannonWorld.gravity.set(0, -10.0, 0);
    // Tweak contact properties.
    // Contact stiffness - use to make softer/harder contacts
    cannonWorld.defaultContactMaterial.contactEquationStiffness = 1e9;
    // Stabilization time in number of timesteps
    cannonWorld.defaultContactMaterial.contactEquationRelaxation = 4;
    // We must add the contact materials to the cannonWorld
    cannonWorld.addContactMaterial(physics_physics);
    // ソルバーの反復回数。世界の制約の品質が決まります。 反復が多いほど、シミュレーションはより正確になります。 ただし、反復が増えると、計算も増える必要があります。 あなたの世界に大きな重力がある場合は、より多くの反復が必要になります。
    solver.iterations = 20;
    // 許容誤差に達すると、システムは収束したと見なされます。
    solver.tolerance = 0.1;
    cannonWorld.solver = new CANNON.SplitSolver(solver);

    this.createlittleMonaBody();
    this.createlittleMonaMesh();
    this.createFirstIslandThree();
    this.createFirstIslandCannon();
    this.createFirstIslandMesh();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // リトルモナーオブジェクト
  get getLittleMonaBody() {
    return littleMonaBody;
  }
  // リトルモナーメッシュ
  get getLittleMonaMesh() {
    return littleMonaMesh;
  }
  // // リトルモナーアニメ
  // get getLittleMonaBodyAnim() {
  //   return littleMonaMixer;
  // }
  // // カード用オブジェクトメッシュ
  // get getCardColliderMeshes() {
  //   return cardColliderMeshes;
  // }
  // // モデルオブジェクト
  // get getFirstIslandBody() {
  //   return firstIslandBodyThree;
  // }
  // // モデルメッシュ
  // get () {
  //   return firstIslandMesh;
  // }
  // // レイキャスト用
  // get getColliderObj() {
  //   return collidersObj;
  // }
  get worldFixedStep() {
    return cannonWorld.fixedStep();
  }
  get copyPos() {
    return this.posCopyToThreeFromCannon();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private posCopyToThreeFromCannon() {
    // 以下の4つはモナ用
    // ↓↓↓↓↓順番変えない↓↓↓↓↓
    littleMonaBody.cannon.quaternion.copy(littleMonaMesh.quaternion);
    littleMonaBody.cannon.position.copy(littleMonaBody.object.position);
    littleMonaBody.object.quaternion.copy(littleMonaBody.cannon.quaternion);
    littleMonaMesh.position.copy(littleMonaBody.cannon.position);

    for(let i = 0; i < cardBodies.length; i++) {
      ITEMS.cardCollider[i].position.copy(cardBodies[i].position);
      // cardColliderMeshes[i].quaternion.copy(cardBodies[i].quaternion);
    }
    
    firstIslandMesh.position.copy(firstIslandBodyCannon.position);
  }
  ////////////////////////////////////////////////////////////////
  // CANNON littleMonaBody
  ////////////////////////////////////////////////////////////////
  private createlittleMonaBody() {
    if (typeof window !== `undefined`) {
      const shape = new CANNON.Box(new CANNON.Vec3(0.0075, 0.02, 0.075))
      littleMonaBody.cannon = new CANNON.Body({
        mass: 100,
        shape: shape,
        fixedRotation: true,
        material: physicsMaterial,
        // linearDamping: 0.99,
        // angularDamping: 0.99,
      });
      littleMonaBody.object = new THREE.Object3D();
      cannonWorld.addBody(littleMonaBody.cannon);

      // littleMona Model
      const assetsPath = '../model/mona10.glb';
      const loader = new GLTFLoader();
      loader.load(assetsPath, (gltf) => {
        gltf.scene.scale.set(1, 0.9, 1);
        gltf.scene.position.set(0, 0, 0);

        littleMonaClips = gltf.animations;
        // animationはgltf.animationsの中の要素
        littleMonaMixer = new THREE.AnimationMixer(gltf.scene);
        const clip = THREE.AnimationClip.findByName(littleMonaClips, "MonaWait01");
        littleMonaAction = littleMonaMixer.clipAction(clip);
        littleMonaAction.play();
        littleMonaBody.object.position.set(FIRST_ISLAND_POS_X, FIRST_ISLAND_POS_Y + 10, FIRST_ISLAND_POS_Z);
        littleMonaBody.object.add(gltf.scene);
	      ITEMS.scene.add(littleMonaBody.object);
        
      });
    }
  }

  littleMonaAnimation(dt: number, animType: string) {
    if(littleMonaClips != null) {
      if(littleMonaCurrentAnim != animType) {
        littleMonaMixer.stopAllAction();
        const clip = THREE.AnimationClip.findByName(littleMonaClips, animType);
        littleMonaAction = littleMonaMixer.clipAction(clip);
      }
      littleMonaCurrentAnim = animType;
      littleMonaAction.play();
    }
    if(littleMonaMixer) {
      littleMonaMixer.update(dt);
    }
  }
  ////////////////////////////////////////////////////////////////
  // CANNON littleMonaMesh
  ////////////////////////////////////////////////////////////////
  private createlittleMonaMesh() {
    const geometry = new THREE.BoxBufferGeometry(0.015, 0.025, 0.12)
    const material = new THREE.MeshBasicMaterial({ visible: false, wireframe: false })
    littleMonaMesh = new THREE.Mesh(geometry, material)
    littleMonaMesh.rotateY(90);
    ITEMS.scene.add(littleMonaMesh);
  }
  ////////////////////////////////////////////////////////////////
  // CANNON CreateCardBody
  ////////////////////////////////////////////////////////////////
  userAddress = "";
  monacardList = null;
  loaderNormal = new THREE.TextureLoader();
  async createCardBodyCannon(userAddr: string, imageQualityState: boolean, fallsRangeState: boolean, cardBoxSizeState: boolean): Promise<boolean> {
    if(this.userAddress != userAddr) {
      this.userAddress = userAddr;
      const assetsBalances = await getAssetsBalancesElectrum(userAddr);
      if(assetsBalances === undefined) return false;
      if(assetsBalances.length === 0) return false;
      const joinedAssetList = getJoinedMonacardList(assetsBalances);
      this.monacardList = await getMonacardInfoMany(joinedAssetList);
    }
    
    // Thumbnail Suffix Thumbnail Name Thumbnail Size Keeps Image Proportions
    // t Small Thumbnail 160x160 Yes
    // m Medium Thumbnail 320x320 Yes
    // l Large Thumbnail 640x640 Yes
    // h Huge Thumbnail 1024x1024 Yes
    const imageQuality = imageQualityState ? "l" : "m";
    const fallsRange = fallsRangeState ? [-800, 600, -2000, 0] : [-600, 500, -1500, -300];
    const cardBoxSizeCannon = cardBoxSizeState ? [0.08, 0.1, 0.08] : [0.04, 0.05, 0.04]
    const cardBoxSizeGeo = cardBoxSizeState ? [0.16, 0.2, 0.16] : [0.08, 0.1, 0.08]

    // console.log("1: " + addr);
		// console.log("2: " + assetsBalances);
		// console.log("3: " + joinedAssetList);
    // console.log("4 imageQuality: " + imageQuality);
    let cardBoxBodyCannon: any;
    let cardBoxMeshCannon: any;
    let cardLink: string;
    let texture: THREE.Texture;
    const shapeCannon = new CANNON.Box(new CANNON.Vec3(cardBoxSizeCannon[0], cardBoxSizeCannon[1], cardBoxSizeCannon[2]));
    const geometry = new THREE.BoxBufferGeometry(cardBoxSizeGeo[0], cardBoxSizeGeo[1], cardBoxSizeGeo[2]);
    
    for(let i = 0; i < this.monacardList.length; i++) {
     
      for(let ii = 0; ii < this.monacardList[i].length; ii++) {
        if(this.monacardList[i][ii].imgur_url === undefined) continue;
        if(this.monacardList[i][ii].imgur_url.slice(0, 22) === "https://img.monaffy.jp") {
          // CORS 制限により表示不可
          // cardLink = this.monacardList[i][ii].imgur_url.replace(/original/, "preview");
          // texture = loaderNormal.load(cardLink)
          continue;
        } else if(this.monacardList[i][ii].imgur_url.slice(-3) === "png") {
          cardLink = this.monacardList[i][ii].imgur_url.replace(".png", imageQuality + ".png");
          texture = this.loaderNormal.load(cardLink)
        } else if(this.monacardList[i][ii].imgur_url.slice(-3) === "jpg") {
          cardLink = this.monacardList[i][ii].imgur_url.replace(".jpg", imageQuality + ".jpg");
          texture = this.loaderNormal.load(cardLink)
        } else if(this.monacardList[i][ii].imgur_url.slice(-3) === "gif") {
          cardLink = this.monacardList[i][ii].imgur_url.replace(".gif", ".mp4");

          const video = document.createElement( 'video' );
          video.setAttribute("id", "video_0" + ii);
          video.src = cardLink;
          video.crossOrigin = "Anonymous";
          video.loop = true;
          video.load();
          video.play();
          texture = new THREE.VideoTexture( video );

        }
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          // transparent: true
        });
        cardBoxBodyCannon = new CANNON.Body({
          mass: 30,
          shape: shapeCannon,
          material: physicsMaterial,
          fixedRotation: true,
          linearDamping: 0.99,
          angularDamping: 1.0
        });
        // Mesh
        cardBoxMeshCannon = new THREE.Mesh(geometry, material);
        const x = (FIRST_ISLAND_POS_X + (this.getRandomInt(fallsRange[0], fallsRange[1]) + (this.getRandomInt(-100, 100))) * 0.005);
        const y = FIRST_ISLAND_POS_Y + 2 + (this.getRandomInt(10, 30)) * 1;
        const z = (FIRST_ISLAND_POS_Z + (this.getRandomInt(fallsRange[2], fallsRange[3]) + (this.getRandomInt(-100, 100))) * 0.005);

        cardBoxBodyCannon.position.set(x, y, z);
        cardBoxMeshCannon.position.copy(cardBoxBodyCannon.position);

        ITEMS.colliders.push(cardBoxMeshCannon);
        ITEMS.cardCollider.push(cardBoxMeshCannon);
        cardBodies.push(cardBoxBodyCannon);
        cannonWorld.addBody(cardBoxBodyCannon);
        this.setMonacardInfo(cardBoxMeshCannon.id, this.monacardList[i][ii]);
      }
    }
    return true;
  }
  private setMonacardInfo(objectId: number, monacardList) {
    monacardInfo.push([
      objectId,
      {
        assetName: monacardList.asset_longname === null ? monacardList.asset_common_name: monacardList.asset_longname,
        assetgroup: monacardList.assetgroup,
        card_name: unescapeProcess(monacardList.card_name),
        owner_name: unescapeProcess(monacardList.owner_name),
        imgur_url: unescapeProcess(monacardList.imgur_url),
        add_description: unescapeProcess(monacardList.add_description),
        tw_id: unescapeProcess(monacardList.tw_id),
        tw_name: unescapeProcess(monacardList.tw_name),
        tag: unescapeProcess(monacardList.tag),
        cid: monacardList.cid,
        ver: monacardList.ver,
        is_good_status: monacardList.asseis_good_statustgroup,
        regist_time: monacardList.regist_time,
        update_time: monacardList.update_time,
      }
    ]);
  }
  getMonacardInfo() {
    return monacardInfo;
  }
  private getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }
  ////////////////////////////////////////////////////////////////
  // CANNON FirstIsland
  ////////////////////////////////////////////////////////////////
  private createFirstIslandThree() {
    if (typeof window !== `undefined`) {
      const assetsPath = '../model/island11.glb';
      const loader = new GLTFLoader();
      loader.load(assetsPath,(gltf) => {
        gltf.scene.name = "PlayIsland";
        gltf.scene.traverse( function ( child: any ) {
          if( child.isMesh ) {
            //child.castShadow = true;
            child.receiveShadow = false;
          }
        });
        gltf.scene.scale.set(1, 1, 1);
        gltf.scene.position.set(FIRST_ISLAND_POS_X, FIRST_ISLAND_POS_Y, FIRST_ISLAND_POS_Z);
        // firstIslandBodyThree.add(gltf.scene);
        ITEMS.scene.add(gltf.scene);
        ITEMS.colliders.push(gltf.scene);
      });
    }
  }
  ////////////////////////////////////////////////////////////////
  // CANNON FirstIsland Cannon Mesh
  ////////////////////////////////////////////////////////////////
  private createFirstIslandCannon() {
    if (typeof window !== `undefined`) {
      const shape = new CANNON.Cylinder(13, 13, 1, 15);
      firstIslandBodyCannon = new CANNON.Body({
        mass: 0,
        shape: shape,
        material: physicsMaterial,
        fixedRotation: true,
        // linearDamping: 0.98,
        // angularDamping: 0.98,
      });
      firstIslandBodyCannon.position.set(FIRST_ISLAND_POS_X + 5, FIRST_ISLAND_POS_Y + 0.14, FIRST_ISLAND_POS_Z - 5);
      cannonWorld.addBody(firstIslandBodyCannon);
    }
  }
  private createFirstIslandMesh() {
    const geometry = new THREE.CylinderGeometry(13, 13, 1, 15, 15);
    const material = new THREE.MeshBasicMaterial({visible: false, color: 0xbdfd1fff, wireframe: true });
    firstIslandMesh = new THREE.Mesh(geometry, material);
    firstIslandMesh.scale.set(1, 1, 1 );
    ITEMS.scene.add(firstIslandMesh);
    ITEMS.colliders.push(firstIslandMesh);
  }
}
// id: 変更されることのないカード番号
// asset_common_name: 一般的なトークン名(UNAGI, UNAGI.AFTERなど)
// asset: Counterblock apiで使う識別子。親トークンならasset_common_nameと同じ、子トークンの場合は「A10770391013707819263」のような文字列
// asset_longname: 子トークン名 (親トークンの場合はnull)
// assetgroup: TrueNFTのグループ名 (TrueNFTでない場合はnull)
// card_name: カード名 ※サニタイズ
// owner_name: 登録者名 ※サニタイズ
// imgur_url: imgurまたはmonappyの画像URL ※サニタイズ
// add_description: カードの説明 ※サニタイズ
// tw_id: 登録者Twitterの固有ID ※サニタイズ
// tw_name: 登録者Twitter名 ※サニタイズ
// tag: タグ情報(カンマ区切り) ※サニタイズ
// cid: IPFSのCID
// ver: "1", "2", "FULLONCHAIN"の3種類。1は従来のMonacard, 2はMonacard2.0, FULLONCHAINは未実装
// is_good_status: 規約に違反している場合「false」, 問題ない場合は「true」
// regist_time: 情報の登録日
// update_time: 情報の更新日
