import * as THREE from "three";
import React, { useEffect } from "react";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import { ObjectLoaderCreateKukan, ObjectLoaderEditKukanFromData } from "./ObjectLoaderKukan";
import { initNewGUI, initGUI, lightHelper_1, lightHelper_2 } from "./ThreeGUIHelper";
// import { checkRendererInfo } from "./CheckPerformance";
import Stats from "stats.js"
import { ITEMS_KUKAN } from "./InitItemsKukan";

const isProduct = true; // デバッグ表示をするかの切り替え
let debugState = 0;
let stats: Stats;
if(typeof window !== `undefined`){ stats = new Stats() };

export let scene: THREE.Scene;
export let camera: THREE.PerspectiveCamera;
let controls: OrbitControls;
let renderer: THREE.WebGLRenderer;
let container: HTMLElement;

let composer3: EffectComposer;
let composer4: EffectComposer;
let fxaaPass: ShaderPass;

const KukanManagedClass = (props) => {

	useEffect(() => {
		
		container = document.getElementById("canvas");
		init();

		if(ITEMS_KUKAN.type === "create") ObjectLoaderCreateKukan(scene, props.kouzou);
		else if(ITEMS_KUKAN.type === "edit") setTimeout(() => { ObjectLoaderEditKukanFromData(scene, props.data, props.kouzou) }, 5000);
	
		createCamera();
		// createLight();

		// if(ITEMS_KUKAN.env === "l") initGUI(props.data);
		if(ITEMS_KUKAN.type === "create") initNewGUI();
		else initGUI(props.data);

		animate();

		window.onerror = function(error){
			console.error(JSON.stringify(error));
		}

		const menu = document.getElementById("menu");
		menu.addEventListener("mouseover", (e) => {
			controls.enabled = false;
		});
		container.addEventListener("mouseover", (e) => {
			controls.enabled = true;
		});
		
		const menuGUI = document.getElementsByClassName("lil-gui")[0];
		menuGUI.addEventListener("mouseover", (e) => {
			controls.enabled = false;
		});
	});
	return (<div id="canvas"></div>)

	// フレーム毎に走る
	function animate() {

		if(ITEMS_KUKAN.env === "l") lightHelper_1.update();
		if(ITEMS_KUKAN.env === "l") lightHelper_2.update();

		renderer.clear();
		requestAnimationFrame(animate);
			renderer.render(scene, camera);
		//   composer3.render();
		//   composer4.render();
		// switchDebugEnv(isProduct);
	}

	function init() {
		// await setTimeOutPromise(2000);

		// const axesHelper = new THREE.AxesHelper(5000);
		// axesHelper.position.set(0, 5, 0);
		// var grid = new THREE.GridHelper(500, 40, 0xffffff, 0x000000);
		// ITEMS_KUKAN.scene.add(axesHelper);
		// ITEMS_KUKAN.scene.add(grid);

		scene = new THREE.Scene();
		scene.fog = new THREE.Fog(0x000000, 1, 500);
			scene.background = new THREE.Color(0xffffff);

		renderer = new THREE.WebGLRenderer({
				alpha: false, antialias: true, preserveDrawingBuffer: true, 
			});
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.autoClear = false;
		renderer.setClearColor(0x000000, 0.0);
		renderer.shadowMap.enabled = true;

		// 以下はモデルの色はBlenderと近くなるが、カードの色が変わるので入れない
		// ITEMS_KUKAN.renderer.outputEncoding = THREE.sRGBEncoding;
		// ITEMS_KUKAN.renderer.toneMapping = THREE.ACESFilmicToneMapping;

		container.appendChild(renderer.domElement);
		createFXAAShader();
		
		window.addEventListener("resize", function () { onWindowResize(); }, false);
	}

	function createCamera() {
		camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
		camera.position.set(0, 30, 30);

		controls = new OrbitControls(camera, document.body);
		controls.enableDamping = true;
		controls.dampingFactor = 0.3;

		controls.maxDistance = 200;

		// 上下の振り幅
		controls.minPolarAngle = 0;
		controls.maxPolarAngle = Math.PI / 2;

		controls.target = new THREE.Vector3(0, 0, 0);
		controls.mouseButtons = {
			LEFT: THREE.MOUSE.ROTATE,
			MIDDLE: THREE.MOUSE.PAN,
			RIGHT: THREE.MOUSE.DOLLY
		};
		
	}

	// function createLight() {
	// 	//光源
	// 	// new THREE.SpotLight(色, 光の強さ, 距離, 照射角, ボケ具合, 減衰率)
	// 	const spotLight = new THREE.SpotLight(0xFFFFFF, 0.8, 100, Math.PI / 2, 1, 1.5);
	// 	spotLight.position.set(0, 30, 30);
	// 	spotLight.castShadow = true;
	// 	spotLight.shadow.mapSize.width = 512;
	// 	spotLight.shadow.mapSize.height = 512;
	// 	scene.add(spotLight);

	// 	//光源2
	// 	const spotLight2 = new THREE.SpotLight(0xFFFFFF, 0.8, 100, Math.PI / 2, 1, 1.5);
	// 	spotLight2.position.set(40, 30, -80);
	// 	spotLight2.castShadow = true;
	// 	spotLight2.shadow.mapSize.width = 512;
	// 	spotLight2.shadow.mapSize.height = 512;
	// 	scene.add(spotLight2);

	// 	// AmbientLight
	// 	const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
	// 	scene.add(ambientLight);

	// 	// HemisphereLight
	// 	const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444, 1.0);
	// 	scene.add(hemisphereLight);

	// }

	function onWindowResize() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize( window.innerWidth, window.innerHeight );
		resizeFAXXShader();
	}

	function createFXAAShader() {
		// antialius の代わり
		// antialius = true より負荷が低いらしい

		const renderPass2 = new RenderPass(scene, camera);
		renderPass2.clearColor = new THREE.Color(0, 0, 0);
		renderPass2.clearAlpha = 0;

		const copyPass = new ShaderPass(CopyShader);
		composer3 = new EffectComposer(renderer);
		composer3.addPass(renderPass2);
		composer3.addPass(copyPass);

		const pixelRatio = renderer.getPixelRatio();

		fxaaPass = new ShaderPass(FXAAShader);
		fxaaPass.material.uniforms[ "resolution" ].value.x = 1 / (container.offsetWidth * pixelRatio);
		fxaaPass.material.uniforms[ "resolution" ].value.y = 1 / (container.offsetHeight * pixelRatio);

		composer4 = new EffectComposer(renderer);
		composer4.addPass(renderPass2);
		composer4.addPass(fxaaPass);
	}

	function resizeFAXXShader() {
		composer3.setSize( container.offsetWidth, container.offsetHeight );
		composer4.setSize( container.offsetWidth, container.offsetHeight );

		const pixelRatio = renderer.getPixelRatio();

		fxaaPass.material.uniforms[ "resolution" ].value.x = 1 / ( container.offsetWidth * pixelRatio );
		fxaaPass.material.uniforms[ "resolution" ].value.y = 1 / ( container.offsetHeight * pixelRatio );

	}

	/**
	 * デバッグ表示をするかの切り替え。
	 * isProduct = false の場合に表示する
	 * @param state
	 */
	function switchDebugEnv(state: boolean) {

		if(state) requestAnimationFrame( () => { animate() });
		else {
			if(typeof window !== `undefined` && debugState === 0) {
				stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
				document.body.appendChild( stats.dom );
				debugState = 1;
			}
			stats.begin();
			requestAnimationFrame( () => { animate(); });
			stats.end();
			// checkRendererInfo(ITEMS_KUKAN.renderer);
		}
	}

}
export default KukanManagedClass;